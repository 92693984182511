<template>

  <Box v-if="ready"> <!-- main -->
  
    <ErrorRow :error="error" />
    <SuccessRow :message="message" /> 
    
    <Row :showBorder="true" >
      <Column :width="11" >
      </Column> <!-- col-11 -->
      
      <Column :width="3">
      </Column>
      <Column :width="1">
        <Button v-on:click="refresh()" :disabled="isLoading">
          Refresh
        </Button>
      </Column>
    </Row>
    
    <LoadingRow v-if="isLoading" :showBorder="true" />
          
    <Row :showBorder="true">
      <div class="rc-box-col-15">
        <div class="rc-container">
    
          <TitleRow> Company </TitleRow>
          
          <LabelValueRow>
            <template #label>Name</template>
            <template #value>{{ companyName() }}</template>
          </LabelValueRow>

          <AddressRow 
            :showTitle="false"
            fontSize="rc-text-small"
            :addressData="addressData()"/>
        
          
          <TitleRow>Details</TitleRow>
          
          <LabelValueRow>
            <template #label>Driver</template>
            <template #value>{{ fullName() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>Date</template>
            <template #value>{{ timesheet.dateDisplay() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>Day Start</template>
            <template #value>Midnight</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>Cycle</template>
            <template #value>{{ timesheet.cycleDisplay() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>Status</template>
            <template #value>
              <Box>
                <Row>
                  <div class="rc-box-col-3 rc-input-good rc-text-centre" v-if="timesheet.isStatusComplete()">
                    {{ timesheet.statusDisplay() }}
                  </div>
                  <div class="rc-box-col-3 rc-input-black rc-text-centre" v-if="timesheet.isStatusCompleteOffDay()">
                    {{ timesheet.statusDisplay() }}
                  </div>
                  <div class="rc-box-col-3 rc-input-minor rc-text-centre" v-else-if="timesheet.isStatusIncomplete()">
                    {{ timesheet.statusDisplay() }}
                  </div>
                  <div class="rc-box-col-3 rc-input-major rc-text-centre" v-else-if="timesheet.isStatusMissing()">
                    {{ timesheet.statusDisplay() }}
                  </div>
                  <div class="rc-box-col-3 rc-input-inprogress rc-text-centre" v-else-if="timesheet.isStatusInProgress()">
                    {{ timesheet.statusDisplay() }}
                  </div>
                  <div class="rc-box-col-12" />
                </Row>
              </Box>
            </template>
          </LabelValueRow>

          <!--
          <TitleRow> Inspections </TitleRow>
        
          <div v-for="(inspection, index) in inspections" :key="100+index" class="row">
            <div class="rc-box-col-15">
              <div class="container">
                <StringValueRow 
                  label="Vehicle"  
                  :value="inspection.vehicle().find().plate()" />
                <StringValueRow label="Odometer" 
                  :value="inspection.odometer()" />
                <StringValueRow label="Status"   
                  :value="defectType(inspection.defectType())" 
                  :stylingValue="defectTypeColour(inspection.defectType())"/>
              </div>
            </div>
            <div class="rc-box-col-15" v-if="inspection.trailers().length() > 0">
              <div class="container" v-for="(trailer, index) in inspection.trailers().list()" :key="1000+index">
                <StringValueRow 
                  label="Trailer"  
                  :value="trailer.find().plate()" />
                <StringValueRow label="Odometer" 
                  :value="odometerForTrailer(inspection, trailer)" />
                <StringValueRow label="Status"   
                  :value="defectType('Good')" 
                  :stylingValue="defectTypeColour('Good')"/>
              </div>
            </div>
          </div>
          
          <LabelValueRow v-if="inspections.length == 0">
            <template #label></template>
            <template #value>No Inspections</template>
          </LabelValueRow>

          -->
        
          <!--
          <TitleRow> Totals </TitleRow>
          
          <LabelValueRow>
            <template #label>On Duty Not Driving</template>
            <template #value>{{ timesheet.onDutyNotDrivingHoursDisplay() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>On Duty Driving</template>
            <template #value>{{ timesheet.onDutyDrivingHoursDisplay() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>On Duty</template>
            <template #value>{{ timesheet.onDutyHoursDisplay() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>Off Duty</template>
            <template #value>{{ timesheet.offDutyHoursDisplay() }}</template>
          </LabelValueRow>

          -->
          
          <TitleRow>Start Location </TitleRow>
        
          <LabelValueRow>
            <template #label>Started At</template>
            <template #value>{{ Contents.decode(timesheet.locationStart()) }}</template>
          </LabelValueRow>

          <TitleRow>Graph</TitleRow>
        
          <div class="row">
            <div class="rc-box-col-15 rc-text-centre" >
              <canvas 
                id="canvas" 
                style="height: 330px; width: 616px; border: 0px solid blue; background: white" />
            </div>
          </div>
          
          <TitleRow>Daily Duty Status</TitleRow>
        
          <div v-for="(data, index) in entryData" :key="index" class="row">
            <div class="rc-box-col-1 rc-text-key rc-text-right">{{ hourDisplay(data) }}</div>
            <div class="rc-box-col-1 rc-text-key rc-text-left">{{ quarterDisplay(data) }} </div>
            <div :class="colourDisplay(data)"> {{ decode(statusDisplay(data)) }} </div>
            <div class="rc-box-col-1" />
          </div>
          
          <TitleRow>End Location</TitleRow>
        
          <LabelValueRow>
            <template #label>Ended At</template>
            <template #value>{{ Contents.decode(timesheet.locationEnd()) }}</template>
          </LabelValueRow>

          <TitleRow>Signature</TitleRow>
        
          <LabelValueRow>
            <template #label>Date</template>
            <template #value>{{ timesheet.completedDateDisplay() }}</template>
          </LabelValueRow>

          <LabelValueRow>
            <template #label>Signature</template>
            <template #value>
              <img width="200px" :src="signatureImage()" />
            </template>
          </LabelValueRow>
          <SpacerRow />
        </div>
      </div>
    </Row>
  </Box><!-- main -->

</template>

<script>

import { mapGetters } from 'vuex'

import Catalog    from "@/domain/session/Catalog.js";
import ConstUtils from '@/utils/ConstUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
//import DateUtils  from '@/utils/DateUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import AddressRow    from '@/components/row/AddressRow.vue'
import ErrorRow from '@/components/row/ErrorRow.vue'

//import Button       from '@/components/button/Button.vue';
import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter.js";
import Timesheet from '@/domain/model/timesheet/Timesheet.js';
import TimesheetMap from '@/domain/model/timesheet/TimesheetMap.js';
import TimesheetEntry from '@/domain/model/timesheet/TimesheetEntry.js';

import Company from '@/domain/model/company/Company.js';
import Address from '@/domain/model/address/Address.js';
import Employee from '@/domain/model/employee/Employee.js';
import User from '@/domain/model/user/User.js';
import Signature from '@/domain/model/user/Signature.js';

import CatelogKeys from "@/domain/session/CanadianEnglish.js";

import Button from "@/portals/shared/library/button/Button.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";
import SuccessRow  from "@/components/row/SuccessRow.vue";

export default {
  name: "operator-timesheet-details",
  components: {
    SpacerRow,
    TitleRow,
    Box, Row, Column,
    AddressRow,
    Button,
    LabelValueRow, LoadingRow,
    ErrorRow, SuccessRow,
  },
  props: {
    
  },
  data() {
    return {
      error: null,
      message: null,
      C: new Catalog(),
      K: Catalog.Timesheet,
      CK: CatelogKeys.KEYS,
      Contents: ContentUtils,
      entryData: [],
      entries: [],
      inspections: [],
      isLoading: false,
      ready: false,
      timesheet: new Timesheet(this.domain),
      address: new Address(this.domain),
      company: new Company(this.domain),
      employee: new Employee(this.domain),
      user: new User(this.domain),
      signature: new Signature(this.domain),
      showSessionTimeoutDialog: false,
    };
  },
  directives: {
    
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
                ]),
    timesheetId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN })
    } else {
      this.timesheet = new Timesheet(this.domain);
      this.ready = true;
      this.refresh();
    }
  },
  methods: {
    loadInspections: function() {
      this.inspections = this.timesheet.inspections().find().list();
    },
    reloadEntryData: function() {
      this.entries = this.timesheet.entries().find();
      for (var hour = 0; hour < 24; hour++) {
        for (var quarter = 0; quarter < 4; quarter++) {
          var data = {
            'hour': hour,
            'quarter': quarter,
          };
          var timeId = TimesheetEntry.newTimeId(hour, quarter);
          data['entry'] = this.entries.entryFor(timeId);
          this.entryData.push(data);
        }
      }
    },
    odometerForTrailer: function(inspection, trailer) {
      var results = inspection.results().find();
      var trailerResults = results.forVehicle(trailer);
      return trailerResults.initial().odometer();
    },
    defectType: function(defectType) {
      if (defectType === 'None') {
        return 'Good';
      }
      if (defectType === 'Minor') {
        return 'Minor';
      }
      if (defectType === 'Major') {
        return 'Major';
      }
      return "";
    },
    defectTypeColour: function(defectType) {
      if (defectType === 'None') {
        return 'rc-background-good rc-text-light rc-text-centre';
      }
      if (defectType === 'Minor') {
        return 'rc-background-minor rc-text-light rc-text-centre';
      }
      if (defectType === 'Major') {
        return 'rc-background-major rc-text-light rc-text-centre';
      }
      return '';
    },
    drawGraph: function() {
      if (!document) {
        return;
      }
      var canvas = document.getElementById('canvas');
      if (!canvas) {
        return;
      }
      var context = canvas.getContext('2d');
      var height = canvas.height;
      var width = canvas.width;
      
      var rowHeight = height / 5;
      var columnWidthHour = width / 28;
      
      context.strokeStyle = '#3F82B6';
      context.lineWidth = 1;
      //Rows
      for (var index = 0; index < 5; index++) {
        if (index == 0 || index == 4) {
          continue;
        }
        var left = columnWidthHour * 3;
        var top = index * rowHeight;
        var right = columnWidthHour * 24;
        var bottom = rowHeight;
        context.strokeRect(left, top, right, bottom);
      }
      
      //columns
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = index * columnWidthHour;
        top = rowHeight;
        right = columnWidthHour;
        bottom = rowHeight * 3;
        context.strokeRect(left, top, right, bottom);
      }
      context.stroke();
      
      context.strokeStyle = '#2B3990';
      context.lineWidth = 1;
      var hourStrings = [
          "M", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "N",
          "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "M"];
      
      for (index = 0; index < hourStrings.length; index++) {
        var hour = hourStrings[index];
        var extra = -2;
        if (hour === "M") {
          extra = -5;
        }
        if (hour === "10") {
          extra = -7;
        }
        if (hour === "11") {
          extra = -5;
        }
        context.strokeText(hour, (index * columnWidthHour) + (columnWidthHour * 3) + extra, rowHeight - 15);
      }

      context.strokeText("OFF", 2, (rowHeight * 1.5));
      context.stroke();
      
      context.strokeStyle = '#3F82B6';
      //halves for row 1 off duty
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + (columnWidthHour / 2);
        top = (rowHeight * 2) - (rowHeight / 2);
        right = 1;
        bottom = (rowHeight / 2);
        context.strokeRect(left, top, right, bottom);
      }
      
      //15 minute quarter for row 1
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + (columnWidthHour / 4);
        top = (rowHeight * 2) - (rowHeight / 3);
        right = 1;
        bottom = (rowHeight / 3);
        context.strokeRect(left, top, right, bottom);
      }
      
      //45 minute quarter for row 1
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + ((columnWidthHour / 4) * 3);
        top = (rowHeight * 2) - (rowHeight / 3);
        right = 1;
        bottom = (rowHeight / 3);
        context.strokeRect(left, top, right, bottom);
      }
      context.stroke();

      context.strokeStyle = '#2B3990';
      context.strokeText("DRV", 2, (rowHeight * 2.5));
      context.stroke();
      
      context.strokeStyle = '#3F82B6';
      //halves for row 2 driving
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + (columnWidthHour / 2);
        top = (rowHeight * 3) - (rowHeight / 2);
        right = 1;
        bottom = (rowHeight / 2);
        context.strokeRect(left, top, right, bottom);
      }

      //15 minute quarter for row 2
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + (columnWidthHour / 4);
        top = (rowHeight * 3) - (rowHeight / 3);
        right = 1;
        bottom = (rowHeight / 3);
        context.strokeRect(left, top, right, bottom);
      }

      //45 minute quarter for row 2
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + ((columnWidthHour / 4) * 3);
        top = (rowHeight * 3) - (rowHeight / 3);
        right = 1;
        bottom = rowHeight / 3;
        context.strokeRect(left, top, right, bottom);
      }
      context.stroke();
      context.lineWidth = 1;
      context.strokeStyle = '#2B3990';
      context.strokeText("ON", 2, (rowHeight * 3.5));
      context.stroke();

      context.strokeStyle = '#3F82B6';
      //halves for row 3 on duty
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + (columnWidthHour / 2);
        top = (rowHeight * 4) - (rowHeight / 2);
        right = 1;
        bottom = rowHeight / 2;
        context.strokeRect(left, top, right, bottom);
      }

      //15 minute quarter for row 3
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + (columnWidthHour / 4);
        top = (rowHeight * 4) - (rowHeight / 3);
        right = 1;
        bottom = (rowHeight / 3);
        context.strokeRect(left, top, right, bottom);
      }

      //45 minute quarter for row 3
      for (index = 0; index < 28; index++) {
        if (index < 3 || index > 26) {
          continue;
        }
        left = (index * columnWidthHour) + ((columnWidthHour / 4) * 3);
        top = (rowHeight * 4) - (rowHeight / 3);
        right = 1;
        bottom = (rowHeight / 3);
        context.strokeRect(left, top, right, bottom);
      }
      context.stroke();

      var lastEntry = "";
      var lastEnd = 0;
      
      context.lineWidth = 2;
      
      if (this.timesheet != null) {
        var entries = this.timesheet.entries().find();
        var sorted = entries.sortByStartTimeId();
        for (index = 0; index < sorted.length; index++) {
          
          var entry = sorted[index];
          if (!entry.isTimeIdStartSet()) {
            continue;
          }
          var start = entry.hourStart() * 4 + entry.quarterStart();
          var end = entry.totalQuarters();

          var widthOfQuarter = columnWidthHour / 4;
          var a = (columnWidthHour * 3) + (start * widthOfQuarter);
          var myX = Math.round(a);
          var endQuarter = (end) * widthOfQuarter;
          var myWidth = Math.round(endQuarter);
          if (StringUtils.isEmpty(lastEntry)) {
            lastEntry = entry.entryType();
          }
          if (entry.isOnDuty()) {
            context.strokeStyle = '#3F82B6';
            left = myX;
            top = (rowHeight * 3.5);
            right = myWidth;
            bottom = (1);
            context.strokeRect(left, top, right, bottom);
            if (!entry.isEntryType(lastEntry)) {
              var y = 0;
              height = rowHeight;
              if (TimesheetEntry.Values.EntryType.isOffDutyAway(lastEntry)) {
                height = (rowHeight * 2);
                y = (rowHeight * 1.5);
              }
              if (TimesheetEntry.Values.EntryType.isOnDutyDriving(lastEntry)) {
                height = (rowHeight * 1);
                y = (rowHeight * 2.5);
              }
              left = lastEnd;
              top = y;
              right = 1;
              bottom = (height);
              context.strokeRect(left, top, right, bottom);
            }
            lastEntry = entry.entryType();
            lastEnd = myX + myWidth;
            context.stroke();

          }

          if (entry.isOnDutyDriving()) {
            context.strokeStyle = 'green';
            left = myX;
            top =  (rowHeight * 2.5);
            right = myWidth;
            bottom = (1);
            context.strokeRect(left, top, right, bottom);
            if (!entry.isEntryType(lastEntry)) {
              y  = 0;
              height = rowHeight;
              if (TimesheetEntry.Values.EntryType.isOnDutyNotDriving(lastEntry)) {
                y =  (rowHeight*2.5);
              }
              if (TimesheetEntry.Values.EntryType.isOffDutyAway(lastEntry)) {
                y =  (rowHeight*1.5);
              }
              left = lastEnd;
              top = y;
              right = 1;
              bottom = (height);
              context.strokeRect(left, top, right, bottom);
            }
            lastEntry = entry.entryType();
            lastEnd = myX + myWidth;
            context.stroke();

          }

          if (entry.isOffDuty() || entry.isOffDutySleeper()) {
            context.strokeStyle = '#8a8986';
            left = myX;
            top =  (rowHeight * 1.5);
            right = myWidth;
            bottom = (1);
            context.strokeRect(left, top, right, bottom);
            if (!entry.isEntryType(lastEntry)) {
              height = 0;
              if (TimesheetEntry.Values.EntryType.isOnDutyNotDriving(lastEntry)) {
                height = (rowHeight*2);
              }
              if (TimesheetEntry.Values.EntryType.isOnDutyDriving(lastEntry)) {
                height = (rowHeight*1);
              }
              left = lastEnd;
              top =  (rowHeight * 1.5);
              right = 1;
              bottom = (height);
              context.strokeRect(left, top, right, bottom);
            }
            lastEntry = entry.entryType();
            lastEnd = myX + myWidth;
            context.stroke();

          }
        }
      }

      context.strokeStyle = '#2B3990';
      context.lineWidth = 1;
      let msg = StringUtils.format("Off Duty: {0} On Duty: {3} [Driving: {1} Not Driving: {2}]",
          [this.timesheet.offDutyHoursDisplay(),
           this.timesheet.onDutyDrivingHoursDisplay(),
           this.timesheet.onDutyNotDrivingHoursDisplay(),
           this.timesheet.onDutyHoursDisplay()]);
      context.strokeText(msg , columnWidthHour*1,  (rowHeight*4)+(rowHeight/2));
      context.stroke();

    },
    hourDisplay: function(data) {
      if (data.quarter == 0) {
        if (data.hour == 0) {
          return "12";
        }
        if (data.hour > 12) {
          return data.hour - 12;
        }
        return data.hour;
      }
      return "";
    },
    quarterDisplay: function(data) {
      if (data.quarter == 0) {
        if (data.hour < 12) {
          return " AM";
        }
        if (data.hour >= 12) {
          return " PM";
        }
        return ":00";
      }
      if (data.quarter == 1) {
        return ":15";
      }
      if (data.quarter == 2) {
        return ":30";
      }
      return ":45";
    },
    statusDisplay: function(data) {
      var entry = data.entry;
      if (entry.hourStart() == data.hour && entry.quarterStart() == data.quarter) {
        var type = data.entry.entryType();
        var dutyStatus = "";
        if (type == "onDutyNotDriving") {
          dutyStatus =  "On Duty Not Driving";
        }
        if (type == "offDuty") {
          dutyStatus =  "Off Duty";
        }
        if (type == "onDutyDriving") {
          dutyStatus =  "On Duty Driving";
        }
        return StringUtils.format("{0} {1} {2}", [dutyStatus, entry.location(), entry.remarks()])
      }
      return "";
    },
    colourDisplay: function(data) {
      var type = data.entry.entryType();
      if (type == "onDutyNotDriving") {
        return "rc-box-col-12 rc-background-primary rc-text-light";
      }
      if (type == "offDuty") {
        return "rc-box-col-12 rc-background-selected";
      }
      if (type == "onDutyDriving") {
        return "rc-box-col-12 rc-background-good rc-text-light";
      }
    },
    addressData: function() {
      if (this.domain) {
        var addressData = this.address.getData();
        return addressData;
      }
      return {};
    },
    signatureImage: function () {
      var value = this.signature.value();
      if (!StringUtils.isEmpty(value)) {
        var data = value.substring(1, value.length - 1).split(',')
        var dataArray = new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        return 'data:image/jpeg;base64,' + btoa(dataArray)
      }
      return ''
    },
    decode: function(value) {
      return ContentUtils.unescape(value);
    },
    
    refresh: function () {
      this.isLoading = true;
      this.timesheet = new Timesheet(this.domain);
      this.address = new Address(this.domain);
      this.company = new Company(this.domain);
      this.user = new User(this.domain);
      this.employee = new Employee(this.domain);
      this.signature = new Signature(this.domain);
      
      this.entries = [];
      this.entryData = [];
      var filter = new TimesheetListFilter();
      var map = new TimesheetMap(this.domain);
      map.add(new Timesheet(this.domain, {'@rid': this.timesheetId}));
      filter
        .with(map)
        .done();

      var event = this.domain
          .events()
          .timesheets()
          .list(filter);
      
      event.send(this.timesheetListListener);
    },
    timesheetListListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      }
      this.timesheet = this.domain.timesheets().findById(this.timesheetId);
      this.company = this.timesheet.company().find();
      this.address = this.company.address().find();
      this.employee = this.timesheet.employee().find();
      this.user = this.employee.user().find();
      this.signature = this.timesheet.signature().find();
      this.isLoading = false;
      this.reloadEntryData();
      this.loadInspections();
      this.drawGraph();
    },
    
    companyName: function() {
      return this.company.name();
    },
    fullName: function() {
      if (this.domain) {
        return this.user.fullName();
      }
      return "";
    },
    toResultColour: function () {
      var result = this.timesheet.status();
      if (result == Timesheet.Values.Status.COMPLETED) {
        return 'rc-input-good';

      } else if (result == Timesheet.Values.Status.COMPLETED_OFF_DAY) {
        return 'rc-input-black';

      } else if (result == Timesheet.Values.Status.INPROGRESS) {
        return 'rc-input-inprogress';
            
      } else if (result == Timesheet.Values.Status.INCOMPLETE) {
        return 'rc-input-minor';
            
      } else {
        return 'rc-input-major';
      }
    },
  }
}
</script>